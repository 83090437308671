import React from "react"
import * as S from "./quote-section.styles.jsx"
import Typography from "@mui/material/Typography"
import SectionWrapper from "../section-wrapper/section-wrapper.component.jsx"
import { graphql, useStaticQuery } from "gatsby"

const QuoteSection = ({ text, author }) => {
  const staticImages = useStaticQuery(
    graphql`
      query {
        quote: file(relativePath: { eq: "quotation.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2080) {
              base64
              aspectRatio
              src
              srcSet
              sizes
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  if (!text) return null
  const parse = require("html-react-parser")

  return (
    <SectionWrapper
      contained
      bg="gray"
      style={{ background: "#F3F0ED", textAlign: "center" }}
    >
      <S.QuoteContainer>
        <S.Quote img={staticImages.quote} />
      </S.QuoteContainer>
      <S.Text>
        <Typography>{text ? parse(text) : ""}</Typography>
      </S.Text>
      <S.AuthorName>{author}</S.AuthorName>
    </SectionWrapper>
  )
}

export default QuoteSection
