import styled from "styled-components"
import { Grid } from "@mui/material"
import Box from "@mui/material/Box"
import Slider from "react-slick"
import FluidImage from "../../components/fluid-image/fluid-image"

export const CoverContainer = styled.div`
  position: relative;
  min-height: 400px;
  margin-top: -7%;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: -15%;
  }

  ${({ theme }) => theme.breakpoints.only("sm")} {
    margin-top: -280px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: -334px;
  }

  .gatsby-image-wrapper {
    z-index: 1;
  }
`
export const BookContent = styled(Grid)`
  p,
  li {
    opacity: 0.5;
    color: #000000;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 29px;
  }

  order: 1;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 2;
  }
`

export const SmallTitle = styled.h3`
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 20px;
  padding-top: 1.5em;
`
export const SliderContainer = styled.div`
  max-width: 364px;

  div {
    text-decoration: none;
    color: black;
  }

  ul {
    list-style-position: inside;
    padding-left: 0;
  }

  li {
    padding-bottom: 0.5em;
  }

  a {
    color: black;
  }

  .arrowsContainer {
    float: right;

    svg {
      color: ${({ theme }) => theme.palette.primary.light};
      opacity: 40%;
      margin-left: 1em;
      cursor: pointer;

      ${({ theme }) => theme.breakpoints.only("sm")} {
        margin-left: 0.5em;
      }

      :hover {
        opacity: 1;
      }
    }
  }
`
export const CustomSlider = styled(Slider)`
  margin-top: 1.5em;
`
export const CustomGridItem = styled(Grid)`
  order: 2;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 1;
  }
`

export const AuthorImageContainer = styled(Box)`
  z-index: 2;
  position: relative;
  margin-top: -30%;

  ${({ theme }) => theme.breakpoints.only("sm")} {
    margin-top: -230px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: -342px;
  }
`
export const SucessStoryContainer = styled.div`
  z-index: 2;
  position: relative;
  margin-top: -8%;

  ${({ theme }) => theme.breakpoints.only("sm")} {
    margin-top: -230px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: -342px;
  }
`
export const RelatedAuthorsTitle = styled.h2`
  color: #0f1418;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 1.88px;
  line-height: 39px;
  padding-bottom: 2em;
  text-align: left;
`
export const AuthorsGrid = styled(Grid)`
  margin-left: -1em;
`
