import { Grid } from "@mui/material"
import styled from "styled-components"
import FluidImage from "../fluid-image/fluid-image"
import Box from "@mui/material/Box"

export const BookContent = styled(Grid)`
  p,
  li {
    opacity: 0.5;
    color: #000000;
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 29px;
  }

  .modal-link {
    color: #0000ee;
    text-decoration: underline;
    cursor: pointer;
  }

  order: 1;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 2;
  }
`
export const WebsiteInfo = styled.div`
  padding: 1.5rem 0;
  width: 100%;
  background-color: #ebe7e1;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    font-weight: bold;
    font-size: 1rem;
    line-height: 22px;
    max-width: 255px;
    color: #000000;
    opacity: 1;
    letter-spacing: 0.04em;
  }
  a {
    margin-left: 0.5em;
    color: #000000;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
    max-width: 200px;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
    padding: 1.5rem 1em;
    p {
      text-align: center;
    }
  }
`
export const CustomGridItem = styled(Grid)`
  order: 2;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    order: 1;
  }
`
export const CoverContainer = styled.div`
  position: relative;
  min-height: 400px;
  margin-top: -7%;
  .book-cover {
    min-height: 250px;
  }
  ${({ theme }) => theme.breakpoints.only("xs")} {
    margin-top: -15%;
  }

  ${({ theme }) => theme.breakpoints.only("sm")} {
    margin-top: -280px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: -334px;
  }

  .gatsby-image-wrapper {
    z-index: 1;
  }
`
export const PodcastContent = styled.a`
  padding-top: 3em;
`
export const CustomBox = styled(Box)`
  max-width: 198px;
  cursor: pointer;
  margin: 0 0;
  padding-top: 3em;
  &:before {
    opacity: 0.5;
    transform: rotate(360deg);
    background: linear-gradient(
      135deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: 16px 25px 30px 0 rgba(0, 0, 0, 0.19);
  }
`

export const BookCover = styled(FluidImage)`
  position: relative;
  border-radius: 5px;
`

export const Date = styled.p`
  color: #999999;
  margin-top: 0;
  font-size: 0.875rem !important;
`
