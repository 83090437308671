import styled from "styled-components"
import FluidImage from "../fluid-image/fluid-image"
import Box from "@mui/material/Box"

export const Content = styled.div`
  padding-top: 3em;
`
export const Title = styled.div`
  h2 {
    font-size: 1rem;
    font-weight: bold;
    line-height: 20px;
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    padding-bottom: 2em;
  }
`
export const BuyContainer = styled.a`
  display: block;
  padding-bottom: 2em;
  max-width: 198px;
`
export const CustomBox = styled(Box)`
  max-width: 198px;
  cursor: pointer;
  margin: 2em 0;
  &:before {
    opacity: 0.5;
    transform: rotate(360deg);
    background: linear-gradient(
      135deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: 16px 25px 30px 0 rgba(0, 0, 0, 0.19);
  }
`
export const BookCover = styled(FluidImage)`
  position: relative;
  border-radius: 5px;
`
