import React from "react"
import * as S from "./book-content-section.styles.jsx"
import SectionWrapper from "../section-wrapper/section-wrapper.component.jsx"
import { Grid } from "@mui/material"
import FluidImage from "../fluid-image/fluid-image"
import BuySection from "../buy-section/buy-section.component"
import { graphql, useStaticQuery } from "gatsby"

const BookContentSection = ({
  title,
  content,
  websiteInfo,
  featuredImage,
  podcastImage,
  podcastLink,
  amazonLink,
  barnesNobleLink,
  bookshopLink,
  date,
  children,
}) => {
  const staticImages = useStaticQuery(
    graphql`
      query {
        amazon: file(relativePath: { eq: "amazon.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
        barnes: file(relativePath: { eq: "barnes.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
        bookshop: file(relativePath: { eq: "bookshop.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
      }
    `
  )
  return (
    <SectionWrapper contained bg="gray">
      <Grid container spacing={9}>
        <S.BookContent item xs={12} sm={7}>
          {date && <S.Date>{date}</S.Date>}
          <div dangerouslySetInnerHTML={{ __html: content }} />
          {websiteInfo && websiteInfo.link && (
            <S.WebsiteInfo>
              {websiteInfo.image && (
                <FluidImage
                  img={websiteInfo.image}
                  alt={websiteInfo.image.altText}
                />
              )}
              <p>
                {websiteInfo.text}
                <a target="_blank" href={websiteInfo.link.url} rel="noreferrer">
                  {websiteInfo.link.title}
                </a>
              </p>
            </S.WebsiteInfo>
          )}
        </S.BookContent>
        <S.CustomGridItem item xs={12} sm={4}>
          <S.CoverContainer>
            <FluidImage
              img={featuredImage && featuredImage.node}
              className="book-cover"
            />
            {podcastImage && (
              <S.PodcastContent
                href={podcastLink}
                target="_blank"
                boxShadow={4}
              >
                <S.CustomBox>
                  <S.BookCover img={podcastImage} />
                </S.CustomBox>
              </S.PodcastContent>
            )}
            {(amazonLink || barnesNobleLink || bookshopLink) && (
              <BuySection
                title={title ?? "<h2>Want to buy this book?</h2>"}
                buyButtons={[
                  {
                    img: staticImages.amazon,
                    target: "_blank",
                    href: amazonLink,
                  },
                  {
                    img: staticImages.barnes,
                    target: "_blank",
                    href: barnesNobleLink,
                  },
                  {
                    img: staticImages.bookshop,
                    target: "_blank",
                    href: bookshopLink,
                  },
                ]}
              />
            )}
            {children}
          </S.CoverContainer>
        </S.CustomGridItem>
      </Grid>
    </SectionWrapper>
  )
}

export default BookContentSection
