import React from "react"
import * as S from "../buy-section/buy-section.styles"
import FluidImage from "../fluid-image/fluid-image"
import { navigate } from "gatsby"
import parse from "html-react-parser"

const BuySection = ({ buyButtons, img, title, bookLink }) => {
  return (
    <S.Content>
      {title ? <S.Title>{parse(title)}</S.Title> : ""}
      {img ? (
        <S.CustomBox onClick={() => navigate(bookLink)} boxShadow={4}>
          <S.BookCover img={img} />
        </S.CustomBox>
      ) : (
        ""
      )}
      {buyButtons.map(buyButton => {
        if (buyButton.href)
          return (
            <S.BuyContainer target={buyButton.target} href={buyButton.href}>
              <FluidImage img={buyButton.img} />
            </S.BuyContainer>
          )
      })}
    </S.Content>
  )
}
export default BuySection
