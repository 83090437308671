import { Typography } from "@mui/material" 
import styled from "styled-components"
import FluidImage from "../fluid-image/fluid-image"

export const Text = styled.div`
  max-width: 954px;
  margin: auto;
  text-align: center;
  p {
    color: ${({ theme }) => theme.palette.primary.light};
    font-size: ${({ theme }) => theme.typography.pxToRem(24)};
    font-weight: bold;
    letter-spacing: 2.13px;
    line-height: 43px;
    text-align: center;
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    margin: 0.5em 0 2em;

    ${({ theme }) => theme.breakpoints.up("md")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(34)};
    }
  }
`

export const AuthorName = styled(Typography)`
  color: #548df7;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 3.33px;
  line-height: 16px;
  text-transform: uppercase;
`

export const QuoteContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2.5em;
`

export const Quote = styled(FluidImage)`
  max-width: 70px;
  display: block;
  margin: 0 auto;
  text-align: center;
  height: auto;
`
